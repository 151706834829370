<template>
  <div>
    <div class="con">
      <!-- 导航 -->
      <div class="dah">
        <div v-for="(item, index) in nvList" :key="index" :class="{ bot: item.value == active }" @click="handclick(item, index)">{{ item.name }}</div>
      </div>
      <div class="conItem" v-loading="loading" element-loading-text="正在加载中,请稍后">
        <!-- 院校 -->
        <div class="school" v-show="active == 1 || active == 2">
          <schoolView :collegeslist="infoData.collegeEntities"></schoolView>
        </div>
        <!-- 专业 -->
        <div class="school" v-show="active == 1 || active == 3">
          <profesSional :subjectCategoriesVos="infoData.subjectCategoriesVos"></profesSional>
        </div>
        <!-- 职业 -->
        <div class="school" v-show="active == 1 || active == 4">
          <occupview :careerEntities="infoData.careerEntities"></occupview>
        </div>
        <!-- 资讯 -->
        <div class="school" v-show="active == 1 || active == 5">
          <zixun :informationEntities="infoData.informationEntities"></zixun>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { queryInfo } from '@/api/resourceAndconsult'
import schoolView from './schoolView.vue'
import profesSional from './professional.vue'
import occupview from './occupview.vue'


import zixun from './zixun.vue'
export default {
  name: "SousoView",
  components: {
    schoolView,
    profesSional,
    zixun,
    occupview
  },
  data() {
    return {
      querName: '',
      nvList: [
        { name: '全部', value: '1' },
        { name: '学校', value: '2' },
        { name: '专业', value: '3' },
        { name: '职业', value: '4' },
        { name: '咨讯', value: '5' },
      ],
      active: 1,
      infoData: {
        careerEntities: [],
        collegeEntities: [],
        informationEntities: [],
        subjectCategoriesVos: []
      },
      loading: true
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(newvalue, oldvalue) {

        if (newvalue.name == 'sousuo') {
          this.querName = this.$route.query.name
          this.request(this.active)
        }
      }
    }
  },
  methods: {
    request(type) {
      this.loading = true
      queryInfo({ name: this.querName, type }).then(res => {
        this.loading = false
        if (type == 1) {
          // 对职业进行id去重
          const map = new Map()
          res.data.careerEntities = res.data.careerEntities.filter(v => !map.has(v.chekId) && map.set(v. chekId, 1))
          // 资讯图片进行转义
          res.data.informationEntities =  res.data.informationEntities.map(item=>{
            return {
              ...item,
              imageUrl:process.env.VUE_APP_BASE_API +item.imageUrl
            }
          })
          this.infoData = res.data
        } else if (type == 2) {
          this.infoData.collegeEntities = res.data.collegeEntities
        } else if (type == 3) {
          this.infoData.subjectCategoriesVos = res.data.subjectCategoriesVos
        } else if (type == 4) {
          this.infoData.careerEntities = res.data.careerEntities.filter(v => !map.has(v.chekId) && map.set(v. chekId, 1))
        } else if (type == 5) {
          this.infoData.informationEntities =  res.data.informationEntities.map(item=>{   //咨讯列表
            return {
              ...item,
              imageUrl:process.env.VUE_APP_BASE_API +item.imageUrl
            }
          })
        }

      })
    },
    handclick(item, index) {
      this.active = item.value
      this.request(item.value)
    }
  },
  created() {
    this.querName = this.$route.query.name
    if (this.querName) {
      this.request(this.active)
    }
  },
  activated() {
    // console.log(this.$route.params.name);
    // debugger
    // if (this.$route.query.name !== this.querName && this.$route.query.name !== undefined) {
    //   this.querName = this.$route.query.name
    //   this.request(this.active)
    // }
  }

}
</script >

<style lang="scss">
.con {
  width: 80%;
  // height: 800px;
  // border: 1px solid #000;
  margin: 4% auto;
  .znztxone{
      font-weight: 900;
      font-size: 16px;
      margin-bottom: 3px;
  }
.znztxtwo{
   font-size: 14px;
   padding-left: 10px;
   span{
    color:red
   }
}
}

.dah {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  border-bottom: 3px dashed #d2d2d2;
  color: #d2d2d2;

  div {
    height: 100%;
    line-height: 50px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .bot {
    color: #000;
    font-weight: 800;
    border-bottom: 5px solid #12b098;
  }
}

.school {
  margin-top: 5%;
}

.ite {
  width: 10%;
  height: 40px;
  // text-align: center;
  padding-left: 10px;
  line-height: 40px;
  border-left: 5px solid #12b098;
}
</style>
