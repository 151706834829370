<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>咨讯</span>
      </div>
      <div>
         <div v-if="informationEntities.length == 0">
              <p class="znztxone">指南针智填友情提醒：</p>
              <p class="znztxtwo">抱歉，没有找到与“<span>{{ $route.query.name }}</span>”相关的资讯！要不去其他频道看看</p>
         </div>
        <!-- <img src="@/assets/imgs/nodata.png" v-if="informationEntities.length == 0" style="display: block; margin: 0 auto;width:30%" alt="" /> -->
        <!-- 资讯列表 -->
        <div class="zx" v-for="(item, index) in informationEntities" :key="index" @click="zxClick(item)">
          <img :src="item.imageUrl" alt="">
          <div class="right">
            <span>{{ item.title }}</span>
            <span>阅读:{{ item.readNum }}</span>
            <span v-show="item.type == 1">置顶</span>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ziXun',
  props: {
    informationEntities: []
  },

  data() {
    return {
      Itemlist: [],
    };
  },
  watch: {

  },

  data() {
    return {
      loading: false,
    };

  },
  methods: {
    zxClick(item) {
      this.$router.push({ path: '/zxxq', query: { infoId: item.infoId } });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/zixun.scss";
</style>
