<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>职业</span>
      </div>
        <div v-if="careerEntities.length == 0">
              <p class="znztxone">指南针智填友情提醒：</p>
              <p class="znztxtwo">抱歉，没有找到与“<span>{{ $route.query.name }}</span>”相关的职业！要不去其他频道看看</p>
         </div>
      <div class="tabArrtwo">
          <span v-for="(item) in careerEntities" :key="item.chekId" class="item" @click="goprofessionDetail(item)">
              {{ item.careerName }}
          </span>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ziXun',
  props: {
    careerEntities: []
  },

  data() {
    return {
      list: [],
    };
  },
  watch: {

  },

  data() {
    return {

    };
  },
  methods: {
          goprofessionDetail(item) {

      this.$router.push({
        path: '/occupationDetail',
        query: { id: item.chekId }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
 .item{
    display: inline-block;
    cursor: pointer;
    margin: 0 5px;
    font-size:16px;
 }
  .item:hover{
    color: #30C9B2;
  }
</style>
