<template>
    <div>
        <el-card>
            <div slot="header" class="clearfix">
                <span>院校</span>

            </div>
            <div v-if="collegeslist.length == 0">
              <p class="znztxone">指南针智填友情提醒：</p>
              <p class="znztxtwo">抱歉，没有找到与“<span>{{ $route.query.name }}</span>”相关的院校！要不去其他频道看看</p>
         </div>
            <div v-else class="collegeslist" style="width: 100%;" :style="{ '--screenhight': screenhight }">
                <div v-loading="loading" element-loading-text="正在加载中,请稍后">
                    <div class="collegelistFather" v-for="(item, index) in newcoll" :key="index"
                        @click="detailbtn(item.collegeNumber)">
                        <div class="collegelist">
                            <img :src="item.collegeLogo" width="94px" height="94px" referrerpolicy="no-referrer" />

                            <div style="margin-left: 20px">
                                <div style="
                            font-weight: bold;
                            font-size: 16px;
                          ">
                                    {{ item.collegeName }}
                                </div>
                                <div style="margin: 7px 0;">
                                    <i class="el-icon-location-outline" style="color: #12B098;"></i>
                                    <span style="font-size: 14px;color: #686868;margin-left: 7px;">{{ item.collegeProvince
                                    }} {{
    item.collegeLocality }}</span>
                                </div>
                                <div>
                                    <span class="TypeandNature" style="margin-left:0rpx">{{ item.collegeType }}</span>
                                    <span class="TypeandNature">{{ item.collegeNature }}</span>
                                </div>
                            </div>
                            <!-- 定位的图片 -->
                            <img src="@/assets/imgs/schooldir.png" style="position: absolute;right:-40px;" alt="">
                        </div>
                    </div>


                </div>
                <div class="fenye" v-show="collegeslist.length !== 0">
                    <el-pagination layout="prev, pager, next" :page-size="8" :total="collegeslist.length"
                        @current-change="handleCurrentChange">
                    </el-pagination>
                </div>

            </div>

        </el-card>
    </div>
</template>

<script>
export default {
    name: "schoolView",
    props: {
        collegeslist: []
    },
    data() {
        return {
            collegelist: [],
            loading: false,
            screenhight: 0,
            newcoll: []
        };
    },
    watch: {
        collegeslist: {
            handler: function (val) {
                // 截取前八个
                this.newcoll = val.slice(0, 8)
            },
            deep: true
        }
    },
    methods: {

        handleCurrentChange(val) {

            // 点击分页切换不同得
            this.newcoll = this.collegeslist.slice((val - 1) * 8, val * 8)
        },
        detailbtn(collegeNumber) {
            this.$router.push({ path: "/profile", query: { collegeNumber: collegeNumber, activeId: this.activeId } });
        },

    },
}
</script>

<style lang="scss" scoped>
.collegeslist {
    box-sizing: border-box;
    min-height: calc(1080px - var(--screenhight));
    width: 100%;
    margin-top: 20px;
    padding: 0 35px 20px 35px;
    // border: 1px solid #f4f4f4;
    border-radius: 10px;
    background: white;

    ::v-deep .el-loading-spinner {
        margin-top: 100px;
        top: 0;
    }

    ::v-deep .el-loading-spinner .path {
        stroke: #30C9B2;
    }

    ::v-deep .el-loading-spinner .el-loading-text {
        color: #30C9B2;
    }

    .collegelistFather {
        display: inline-block;
        width: 43%;
    }

    .collegelistFather:nth-child(odd) {
        margin-right: 105px;
    }

    .collegelist {
        width: 100%;
        height: 130px;
        // border-bottom: 2px solid #f2f2f2;
        display: flex;
        position: relative;
        align-items: center;


        .details {
            width: 80px;
            height: 35px;
            border-radius: 5px;
            border: 2px solid #30C9B2;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            user-select: none;
        }

        .TypeandNature {
            color: #30cab2;
            background: white;
            border: 2px solid #30cab2;
            padding: 2px 7px;
            border-radius: 15px;
            display: inline-block;
            margin: 0 3px;
            font-size: 12px;
        }
    }
}

.fenye {
    margin-top: 20px;
    text-align: center;
}
</style>
