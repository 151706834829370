<template>
    <div>
        <el-card>
            <div slot="header" class="clearfix">
                <span>专业</span>
            </div>
            <div>

                  <div v-if="subjectCategoriesVos.length == 0">
              <p class="znztxone">指南针智填友情提醒：</p>
              <p class="znztxtwo">抱歉，没有找到与“<span>{{ $route.query.name }}</span>”相关的专业！要不去其他频道看看</p>
         </div>
                <div class="bottom" ref="DOM" v-loading="loading" v-else>
                    <div class="professionCss" v-for="(item, index) in subjectCategoriesVos" :key="index">
                        <div>
                            <h1>{{ item.subjectcategoriesName }}
                                <span style="font-size: 14px;color: #686868;margin-left: 30px;font-weight: 100;">{{
                                    item.majorPlusVos.length }}个专业类</span>
                            </h1>
                        </div>
                        <div :style="{
                            borderBottom:
                                indexx != item.majorPlusVos.length - 1 ? '2px solid #f4f4f4' : '',
                        }" style="margin-bottom: 10px;" v-for="(itemm, indexx) in item.majorPlusVos" :key="indexx">
                            <div style="display: flex;">


                                <div class="secondMajor">{{ itemm.majorplusName }}</div>
                                <div style="flex: 6;">


                                    <div v-for="(itemmm, indexxx) in itemm.professionalMajorVos"
                                        :title="itemmm.professionalName" :key="indexxx" class="professionItem inlineCss"
                                        @click="goprofessionDetail(itemmm)">
                                        {{ itemmm.professionalName }}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </el-card>
    </div>
</template>

<script>
export default {
    name: 'profesSional',
    props: {
        subjectCategoriesVos: []
    },
    data() {
        return {
            loading: false,
        };
    },
    created() {

    },
    watch: {
        subjectCategoriesVos: {
            handler: function (val) {
                // 截取前八个
                // console.log('数据更新了', val);
            },
            deep: true
        }
    },
    methods: {
        goprofessionDetail(item) {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            this.$router.push({
                path: '/goprofessionDetail',
                query: { professionalCode: item.professionalCode, scrollTop: scrollTop }
            })
        }
    }
}
</script>

<style lang="scss">
.bottom {
    box-sizing: border-box;
    // min-height: 800px;
    width: 100%;
    // margin-top: 20px;
    // padding: 35px 35px 20px 35px;
    // border: 1px solid #f4f4f4;
    // border-radius: 10px;
    background: white;

    .professionCss {
        border-bottom: 2px solid #f4f4f4;
        padding-bottom: 0px;
        margin-bottom: 12px;

        h1 {
            display: inline-block;
            font-size: 16px;
            font-weight: bold;
            color: #30C9B2;
            margin-bottom: 10px;
        }

        .secondMajor {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #3E3E3E;

            flex: 1;
        }

        .professionItem {
            font-size: 16px;
            cursor: pointer;
            display: inline-block;
            width: 20%;
            color: #686868;
            margin-bottom: 13px;

            span:nth-of-type(1) {
                display: inline-block;
            }

            span:nth-of-type(1):hover {
                color: #30C9B2;
            }
        }

        .professionItem:hover {
            color: #30C9B2;
        }
    }
}
</style>
